import { DiContainer } from '@jack-henry/frontend-utils/di';
import { ObservationSource } from '@jack-henry/frontend-utils/observable';
import { FetchFn } from '@treasury/utils';
import { ConfigurationService } from '../config';
import { AUTH_TOKEN, getAuthToken, updateAuthToken } from './request-validation.js';
import {
    TmHttpConfig,
    TmHttpConfigArrayBuffer,
    TmHttpConfigBlob,
    TmHttpConfigRaw,
    TmHttpConfigText,
    TmHttpResponseType,
} from './tm-http-client.types';

type DefaultConfig = Required<Pick<TmHttpConfig, 'method' | 'responseType' | 'withoutBase'>>;
const defaultConfig: DefaultConfig = {
    method: 'GET',
    responseType: TmHttpResponseType.Json,
    withoutBase: false,
};

/**
 * HTTP Client that wraps a `fetch()` implementation
 * to include Treasury Management-specific headers and behavior.
 */
export class TmHttpClient {
    constructor(
        private readonly fetchFn: FetchFn,
        private readonly configService: ConfigurationService
    ) {}

    /**
     * Convenience method for locations that don't have access to DI.
     */
    public static async getInstance() {
        return (await DiContainer.getInstance()).get(TmHttpClient);
    }

    private readonly expirationStream = new ObservationSource<void>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly sessionExpired$ = this.expirationStream.toObservable();

    private readonly errorSource = new ObservationSource<Error>();

    // eslint-disable-next-line @typescript-eslint/member-ordering
    public readonly error$ = this.errorSource.toObservable();

    private _authToken?: string;

    public get authToken() {
        if (!this._authToken) {
            this._authToken = getAuthToken() ?? undefined;
        }

        return this._authToken;
    }

    public set authToken(token) {
        if (token === this.authToken) {
            return;
        }

        this._authToken = token;
        updateAuthToken(token);
    }

    private get baseUrl() {
        return this.configService.apiRoot;
    }

    public request(url: string, config: TmHttpConfigText): Promise<string>;

    public request(url: string, config: TmHttpConfigArrayBuffer): Promise<ArrayBuffer>;

    public request(url: string, config: TmHttpConfigRaw): Promise<Response>;

    public request(url: string, config: TmHttpConfigBlob): Promise<Blob>;

    public request<T>(url: string, config?: TmHttpConfig): Promise<T>;

    public async request<T extends object>(url: string, config?: TmHttpConfig) {
        const normalizedConfig = this.normalizeConfig(config);
        const { responseType, withoutBase } = normalizedConfig;
        const preserveResponse = responseType === TmHttpResponseType.Raw;
        const requestInit = this.createRequestInit(normalizedConfig);
        const response = await this.fetchFn(this.resolveUrl(url, withoutBase), requestInit);

        if (await this.isBadResponse(response)) {
            return this.handleBadResponse(response);
        }

        const authToken = response.headers.get(AUTH_TOKEN);
        if (authToken) {
            this.authToken = authToken;
        }

        if (preserveResponse) {
            return response;
        }

        return this.transformResponse<T>(response, responseType);
    }

    /**
     * Generate an error out of a response determined to be unusable.
     * Can be overridden in subclasses to enable error generation with more specific contexts.
     */
    protected async transformBadResponse(response: Response): Promise<Error> {
        try {
            const responseText = await response.clone().text();
            return new Error(responseText);
        } catch {
            return new Error('An unknown error occurred.');
        }
    }

    protected normalizeConfig(config: TmHttpConfig = defaultConfig) {
        return {
            ...defaultConfig,
            ...config,
        };
    }

    protected extractBody(config: TmHttpConfig) {
        return config.body ?? undefined;
    }

    /**
     * Convert the response into the format specified by the config options.
     */
    protected async transformResponse<T extends object>(
        response: Response,
        responseType: TmHttpResponseType
    ) {
        // clone response so it can be deserialized multiple times if retrieved from cache
        const clone = response.clone();
        switch (responseType) {
            case TmHttpResponseType.Raw:
                return clone;
            case TmHttpResponseType.ArrayBuffer:
                return clone.arrayBuffer();
            case TmHttpResponseType.Blob:
                return clone.blob();
            case TmHttpResponseType.Json:
                return clone.json() as T;
            case TmHttpResponseType.Text:
            default:
                return clone.text();
        }
    }

    /**
     * Check to determine if a response is considered in an error state
     * and should be handled or an exception thrown.
     *
     * Subclasses can override to layer additional logic or replace entirely.
     */
    protected isBadResponse(resp: Response): Promise<boolean> {
        return Promise.resolve(!resp.ok);
    }

    private resolveUrl(url: string, ignoreBase: boolean) {
        if (ignoreBase) {
            return url;
        }

        return `${this.baseUrl}/${url}`;
    }

    private createRequestInit(config: TmHttpConfig) {
        const { method, headers } = config;
        const body = this.extractBody(config);
        const disableContentType = body instanceof FormData || !!config.disableContentType;
        const autoHeaders = this.createHeaders(disableContentType);
        const reqConfig: RequestInit = {
            method,
            headers: {
                ...autoHeaders,
                ...headers,
            },
            credentials: 'include',
        };

        if (body) {
            reqConfig.body = disableContentType ? (body as FormData) : JSON.stringify(body);
        }

        return reqConfig;
    }

    /**
     * Create the set of headers that should be included on every request to a TM API.
     */
    private createHeaders(disableContentType: boolean) {
        const autoHeaders: HeadersInit = {
            'x-tm-client-web': 'true',
        };

        if (!disableContentType) {
            autoHeaders['Content-Type'] = 'application/json';
        }

        if (this.authToken) {
            autoHeaders[AUTH_TOKEN] = this.authToken;
            autoHeaders['Jha-Treasury-ClientInfo'] = 'Web';
        }

        return autoHeaders;
    }

    private async handleBadResponse(response: Response) {
        // unauthorized due to expired token
        if (response.status === 401) {
            this.authToken = undefined;
            this.expirationStream.emit();
        }

        const error = await this.transformBadResponse(response);
        this.errorSource.emit(error);

        throw error;
    }
}
