import { createUniqueId } from '@jack-henry/frontend-utils/functions';
import { CountryModelDto, FrequencyModelDto } from '@treasury/api/channel';
import { exists } from '@treasury/utils';
import { format } from 'date-fns';

export const formatDay = (day = 0) => {
    switch (day) {
        case 1:
        case 21:
        case 31:
            return `${day}st`;
        case 2:
        case 22:
            return `${day}nd`;
        case 3:
        case 23:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

export const getStartAndEnd = (frequency: FrequencyModelDto) => {
    if (frequency.endOn) {
        return `from ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')} to ${format(
            new Date(frequency.endOn),
            'MM/dd/yyyy'
        )}`;
    }
    return `starting on ${format(new Date(frequency.startOn || ''), 'MM/dd/yyyy')}`;
};

export const getDaysOfMonth = (frequency: FrequencyModelDto, twoDays = false) => {
    const day1 = frequency.repeatOnDay1;
    const day2 = frequency.repeatOnDay2;

    if (twoDays) {
        if (frequency.repeatOnLastBusinessDay) {
            return `the ${formatDay(day1)} and the last business day`;
        }
        return `the ${formatDay(day1)} and ${formatDay(day2)}`;
    }

    if (frequency.repeatOnLastBusinessDay) {
        return 'the last business day';
    }
    return `the ${formatDay(day1)}`;
};

export const getDayForAnnual = (date: string) => {
    const tempDate = new Date(date);
    return format(tempDate, 'MM/dd');
};

export const getDayForADate = (date: string) => formatDay(Number(format(new Date(date), 'd')));

export const getCreditorIsoAddress = function (
    address: any,
    countries?: CountryModelDto[]
): string[] {
    const lines = [];
    // Line 1 – Building Number, Street Name
    if (address.buildingNumber || address.streetName) {
        const line1 = [address.buildingNumber, address.streetName].filter(Boolean).join(' ');
        lines.push(line1);
    } else if (address.addressLine1 || address.addressLine2 || address.addressLine3) {
        const line1 = [address.addressLine1, address.addressLine2, address.addressLine3]
            .filter(Boolean)
            .join(' ');
        lines.push(line1);
    }
    // Line 2 – City/Town Name, State/Country Sub division, Post Code
    if (address.city || address.state || address.postalCode) {
        let line2 = [address.city, address.state].filter(Boolean).join(', ');
        if (address.postalCode) {
            line2 = [line2, address.postalCode].filter(Boolean).join(' ');
        }
        if (line2 && address.postalCodeExtension) {
            line2 = [line2, address.postalCodeExtension].filter(Boolean).join('-');
        }
        lines.push(line2);
    }
    // Line 3 – Country Code
    if (address.country) {
        if (countries) {
            lines.push(getCountryNameFromCode(address.country, countries));
        } else {
            lines.push(address.countryCode);
        }
    }
    // Lines 4-11 – Department, Sub Department, Post Box, Building Name, Floor, Room, Town Location Name, District Name
    optionalLocationFieldIds.forEach(field => {
        if (address[field]) {
            lines.push(address[field]);
        }
    });
    return lines;
};

export const getCountryNameFromCode = function (countryCode: string, countries: CountryModelDto[]) {
    let result = null;
    const country = countries.find(country => {
        if (country.countryCode === countryCode) {
            result = country.countryName;
            return true;
        }
        return;
    });
    return result;
};

export const acceptedAdditionalInfoChars = /^[a-zA-Z0-9!#&%*=^_`{}|~";@\[\]\ ]+$/;

export const additionalLocationInfoFields = [
    {
        name: 'Department',
        id: 'department',
        index: 0,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Sub department',
        id: 'subDepartment',
        index: 1,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Post box',
        id: 'postBox',
        index: 2,
        charLimit: 16,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Building Name',
        id: 'buildingName',
        index: 3,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Floor',
        id: 'buildingFloor',
        index: 4,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Room',
        id: 'buildingRoom',
        index: 5,
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'Town location name',
        id: 'townLocationName',
        index: 6,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
    {
        name: 'District name',
        id: 'districtName',
        index: 7,
        charLimit: 35,
        regex: acceptedAdditionalInfoChars,
    },
];

export const getEditableAdditionalLocation = (creditor: any) => {
    return additionalLocationInfoFields
        .filter(field => exists(creditor[field.id]))
        .map(field => ({
            type: field,
            value: creditor[field.id],
            uid: createUniqueId(),
        }));
};

export const getBlankAdditionalLocationInfo = (): {
    value: string;
    type: {
        name: string;
        id: string;
        charLimit: number;
        regex: RegExp;
    };
    uid: string;
} => ({
    value: '',
    type: {
        name: 'Select type',
        id: 'blank',
        charLimit: 70,
        regex: acceptedAdditionalInfoChars,
    },
    uid: createUniqueId(),
});

export const optionalLocationFieldIds = additionalLocationInfoFields.map(field => field.id);
