import { CompanyAccountModelDto } from '@treasury/api/bo';
import { clone } from '@treasury/utils';
import { DomainEntity } from '../../../../shared';

const nullCompanyAccount: CompanyAccountModelDto = {
    id: 0,
    updatedDate: new Date().toDateString(),
    name: '',
    number: '',
    status: '',
    type: '',
    perTransactionLimit: 0,
    dailyLimit: 0,
    cifNumber: '',
    accountUniqueId: '',
};

export class CompanyAccount extends DomainEntity<CompanyAccountModelDto> {
    constructor(dto = clone(nullCompanyAccount)) {
        super(dto);
    }

    public createDefault(): this {
        return new CompanyAccount(clone(nullCompanyAccount)) as this;
    }

    public get id() {
        return this.dto.id;
    }

    public get accountId() {
        return this.dto.account?.id;
    }

    public get accountNumber() {
        return this.dto.number;
    }

    public get cifNumber() {
        return this.dto.cifNumber;
    }

    public get accountName() {
        return this.dto.name;
    }

    public get coreStatus() {
        return this.dto.account?.coreStatus;
    }

    public get number() {
        return this.dto.number;
    }

    public get type() {
        return this.dto.type;
    }

    public get status() {
        return this.dto.status;
    }
}
