/* eslint-disable no-useless-constructor */
import { Injectable } from '@jack-henry/frontend-utils/di';
import {
    BanksClient,
    BeneficiariesClient,
    BeneficiarySearchCriteriaModelDto,
    CountriesClient,
    CreditorModelDto,
    CreditorsClient,
    SearchCreditorsRequestDto,
    StatesClient,
} from '@treasury/api/channel';
import { AnalyticsService } from '@treasury/core/analytics';
import { WireBeneficiary } from './wire-beneficiary.entity';
import { WireCreditor } from './wire-creditor.entity';
import { WireIsoService } from './wireIso.service';

@Injectable()
export class CreditorsService {
    constructor(
        private readonly beneficiariesClient: BeneficiariesClient,
        private readonly creditorsClient: CreditorsClient,
        private readonly wireIsoService: WireIsoService,
        private readonly analytics: AnalyticsService,
        private readonly statesClient: StatesClient,
        private readonly countriesClient: CountriesClient,
        private readonly banksClient: BanksClient
    ) {}

    private getWireIsoFf() {
        return this.wireIsoService.getEntitlementWireISO20022();
    }

    public async getAll() {
        if (await this.getWireIsoFf()) {
            const response = await this.creditorsClient.creditorsSearch(null);
            return response.data.map(account => new WireCreditor(account));
        } else {
            const response = await this.beneficiariesClient.beneficiariesGetAll();
            return response.data.map(account => new WireBeneficiary(account));
        }
    }

    public async getById(id: number) {
        const response = await this.beneficiariesClient.beneficiariesGet(id);
        return new WireBeneficiary(response.data);
    }

    public async create(creditor: CreditorModelDto) {
        return this.getWireIsoFf().then(wireIsoFf => {
            return wireIsoFf
                ? this.creditorsClient.creditorsPost(creditor)
                : this.beneficiariesClient.beneficiariesPost(creditor);
        });
    }

    public async getBeneficiariesByType(searchParameters: SearchCreditorsRequestDto) {
        if (await this.getWireIsoFf()) {
            const response = await this.creditorsClient.creditorsSearch(searchParameters);
            return response.data.map(account => new WireCreditor(account));
        } else {
            const response = await this.beneficiariesClient.beneficiariesSearch(
                searchParameters as unknown as BeneficiarySearchCriteriaModelDto
            );
            return response.data.map(account => new WireBeneficiary(account));
        }
    }

    public async getBanks() {
        const response = await this.banksClient.banksGet();
        return response.data;
    }

    public async getStates() {
        const response = await this.statesClient.statesGet();
        return response.data;
    }

    public async getCountries() {
        const response = await this.countriesClient.countriesGet();
        return response.data;
    }
}
