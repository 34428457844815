/**
 * @typedef { ReturnType<import('../../../../services/achBatchSrvc.js').AchBatchService> } AchBatchService
 */

/**
 *
 * @param { ng.IScope } $scope
 * @param { ng.IRootScopeService } $rootScope
 * @param { ng.IWindowService } $window
 * @param { ng.ITimeoutService } $timeout
 * @param { AchBatchService } achBatchService
 */
export default function BatchDetailController(
    $scope,
    $rootScope,
    $modal,
    $state,
    $window,
    $timeout,
    modalService,
    entitlementsService,
    achBatchService,
    achCompaniesService,
    achSettingsService,
    achBankService,
    securityService,
    activityService,
    resourceType,
    auditCode,
    regexConstants,
    recipientValidatorService
) {
    const batchDetailState = 'payables.ach.batch-detail';
    const taxTemplateDetailState = 'payables.ach.batch-tax-detail';
    const createBatchState = 'payables.ach.batch.create';
    const createTaxTemplateState = 'payables.ach.batch.createTaxTemplate';
    const batchListState = 'payables.ach.batch-list';
    const taxTemplateListState = 'payables.ach.payments.tax-templates';

    if ($state.params.type === 'import' && !$scope.payment) {
        $state.go(getCreateStateName());
    }

    $scope.batch = {};
    $scope.form = {};
    $scope.achCompanies = [];
    $scope.secCodes = [];
    $scope.isNameEditing = false;
    $scope.searchFilters = {
        text: '',
        prenote: false,
        hold: false,
    };
    $scope.alphanumericRegex = regexConstants.AlphaNumericRegex;
    $scope.alphanumericSplCharsRegex = regexConstants.AlphaNumericPlusSpecialCharsRegex;
    $scope.numericRegex = /^\d+$/;
    $scope.batchId = parseInt($state.params.id, 10);

    $scope.resetErrors = resetErrors;
    $scope.showAddendaModal = showAddendaModal;
    $scope.setForm = setForm;
    $scope.confirmCancel = confirmCancel;
    $scope.findAchCompany = findAchCompany;
    $scope.validateAchCompany = validateAchCompany;
    $scope.filterRecipients = filterRecipients;
    $scope.setReviewMode = setReviewMode;
    $scope.goToBatchList = goToBatchList;
    $scope.createBatch = createBatch;
    $scope.getRecipientErrors = getRecipientErrors;
    $scope.valid = valid;
    $scope.invalid = invalid;
    $scope.approveOrReject = approveOrReject;
    $scope.deleteBatch = deleteBatch;
    $scope.editBatch = editBatch;
    $scope.editName = editName;
    $scope.goBack = goBack;
    $scope.confirm = confirm;
    $scope.reviewImport = reviewImport;
    $scope.print = print;
    $scope.cancelImport = cancelImport;
    $scope.hasAccessPermissions = hasAccessPermissions;
    $scope.wizardStep = 2;
    $scope.searchByText = searchByText;
    $scope.validateTemplateName = validateTemplateName;
    $scope.hasErrorDetails = hasErrorDetails;
    $scope.counts = {
        prenote: 0,
        hold: 0,
    };

    init();

    function hasErrorDetails() {
        return $scope.errorDetails && $scope.errorDetails.length > 0;
    }

    function isBatchDetail() {
        return $state.current.name === batchDetailState;
    }

    function isTaxTemplateDetail() {
        return $state.current.name === taxTemplateDetailState;
    }

    function getListStateName() {
        if (isBatchDetail()) return batchListState;

        if (isTaxTemplateDetail()) return taxTemplateListState;

        return null;
    }

    function getCreateStateName() {
        if (isBatchDetail()) return createBatchState;

        if (isTaxTemplateDetail()) return createTaxTemplateState;

        return null;
    }

    function init() {
        // check for template in params and set batch and master recipients
        $scope.type = $state.params.type;
        $scope.editBatchEntitlement =
            entitlementsService.hasEntitlement('Full Edit Ach Template') ||
            entitlementsService.hasEntitlement('Partial Edit Ach Template');
        $scope.partialEditBatchEntitlement =
            entitlementsService.hasEntitlement('Partial Edit Ach Template') &&
            !entitlementsService.hasEntitlement('Full Edit Ach Template');
        $scope.approveBatchEntitlement = entitlementsService.hasEntitlement('Approve Ach Template');
        $scope.deleteBatchEntitlement = entitlementsService.hasEntitlement('Delete Ach Template');
        $scope.name = $scope.batch.name;
        getAchCompanies();
        getAccountTypes();
        getTransactionTypes();
        getBatchDetail($scope.batchId);
        achSettingsService.get().then(response => {
            $scope.achSettings = response;
        });

        $scope.restrict = entitlementsService.hasEntitlement('Restricted Batch');
    }

    function validateTemplateName() {
        if ($scope.batch.name == undefined) return;

        $scope.form.$setValidity('uniqueBatchName', true);
        if ($scope.batch.name === $scope.name) {
            return;
        }

        achBatchService.validateTemplateName($scope.batch.name).then(resp => {
            $scope.form.$setValidity('uniqueBatchName', !!resp.isValid);
            $scope.name = $scope.batch.name;
        });
    }

    function searchByText() {
        $scope.filteredRecipients = filterRecipients();
    }
    function resetErrors() {
        $scope.form.$setValidity('onUsTransactionsRequired', true);
        $scope.form.$setValidity('onUsAmountRequiredPerBatch', true);
    }

    function hasAccessPermissions(batch, permissionType) {
        let result = false;
        angular.forEach(batch.permissions, permission => {
            if (permission.permission === permissionType) {
                result = true;
            }
        });
        return result;
    }

    function confirm() {
        angular.element('.panel-body').scrollTop(0);

        if ($state.params.type === 'import') {
            securityService
                .verifyUser('Create Batch', $scope.batch, () =>
                    achBatchService.create($scope.batch)
                )
                .then(response => {
                    $scope.batch = response.batch;
                    $scope.batch.isTemplate = true;
                    $scope.isConfirmation = true;
                    $scope.wizardStep = 4;
                    $scope.batch.numberOfApprovalsNeeded = response.batch.numberOfApprovalsNeeded;
                });
        } else {
            securityService
                .verifyUser('Edit Batch', $scope.batch, () =>
                    achBatchService.updateBatch($scope.batchId, $scope.batch)
                )
                .then(response => {
                    $scope.batch.status = response.batch.status;
                    $scope.batch.successMessage = response.batch.successMessage;
                    $scope.batch.audit = response.batch.audit;
                    $scope.batch.numberOfApprovalsNeeded = response.batch.numberOfApprovalsNeeded;
                    $scope.isConfirmation = true;
                });
        }
    }

    function deleteBatch() {
        const modalOptions = {
            closeButtonText: 'Cancel',
            actionButtonText: 'Delete',
            headerText: 'Delete Template',
            bodyText: 'Are you sure you want to delete this Template?',
            submit(result) {
                modalInstance.close(result);
            },
        };

        var modalInstance = modalService.showModal({}, modalOptions);

        modalInstance.result.then(result => {
            const deleteBatchModel = {
                achBatchId: $scope.batchId,
            };
            securityService
                .verifyUser('Delete Batch', deleteBatchModel, () =>
                    achBatchService.deleteBatch(deleteBatchModel).then(response => response.data)
                )
                .then(response => {
                    $state.go(getListStateName());
                });
        });
    }

    function setForm(form) {
        $scope.form = form;
    }

    function editName() {
        $scope.isNameEditing = true;
    }

    function reviewImport() {
        angular.element('.panel-body').scrollTop(0);

        $scope.isImporting = false;
        $scope.isEditing = false;
        $scope.isReviewing = true;

        $scope.wizardStep = 3;
        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    }

    function confirmCancel() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel the changes?',
                submit(result) {
                    activityService.userInteractionAudit(
                        resourceType.PaymentResources,
                        auditCode.CancelEditBatchDetail
                    );

                    $modalInstance.close();

                    $scope.batch = angular.copy($scope.copyBatch);
                    achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
                    $scope.isEditing = false;

                    if ($scope.previousState.name.indexOf('createFromBatch') === -1) {
                        $state.go(getListStateName());
                    } else {
                        $window.history.back();
                    }
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            activityService.userInteractionAudit(
                resourceType.PaymentResources,
                auditCode.CancelEditBatchDetail
            );

            $scope.isEditing = false;

            if ($scope.previousState.name.indexOf('createBatch') === -1) {
                $state.go(getListStateName());
            } else {
                $window.history.back();
            }
        }
    }

    function editBatch() {
        securityService.verifyUser('Edit Batch').then(() => {
            $scope.isEditing = true;
            $scope.isNameEditing = false;

            achBankService.getAllBanks().then(response => {
                $rootScope.banks = response;
                $timeout(() => {
                    angular.forEach($scope.batch.recipients, (item, i) => {
                        angular.forEach(
                            [
                                'name',
                                'idNumber',
                                'accountNumber',
                                'routingNumber',
                                'transactionType',
                            ],
                            key => {
                                $scope.form[`recipient-${i}`][key].$validate();
                            }
                        );
                    });
                });
            });
        });
    }

    function addMasterRecipients() {
        if ($state.params.masterRecipients) {
            $state.params.masterRecipients.forEach(masterRecipient => {
                $scope.batch.recipients.push(masterRecipient);
            });
            $timeout(() => {
                $scope.form.$setDirty(true);
            });
        }
    }

    function goToBatchList() {
        $state.go(getListStateName());
    }

    function getAchCompanies() {
        achCompaniesService.getAll().then(data => {
            $scope.achCompanies = data;
        });
    }

    function getAccountTypes() {
        $scope.accountTypes = achBatchService.getAccountTypes();
    }

    function getTransactionTypes() {
        $scope.transactionTypes = achBatchService.getTransactionTypes();
    }

    function getBatchDetail(id) {
        if ($scope.payment) {
            if ($scope.payment.achBatchSummaries) {
                // get batch from collection of batch summaries
                angular.forEach($scope.payment.achBatchSummaries, item => {
                    if (item.id === id) {
                        $scope.batch = item;
                        editBatch();
                    }
                });
            } else {
                $scope.batch = $scope.payment;
                $scope.isImporting = true;
            }
            $scope.batch.isTemplate = true;
            $scope.copyBatch = angular.copy($scope.batch);
            achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
            getAchSecCodes($scope.batch.achCompany.id);
            $scope.filteredRecipients = filterRecipients();
        } else {
            achBatchService.getDetails(id).then(response => {
                $scope.batch = response.batch;
                $scope.name = $scope.batch.name;
                $scope.batch.isTemplate = true;
                $scope.copyBatch = angular.copy($scope.batch);
                achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
                getAchSecCodes($scope.batch.achCompany.id);
                if ($scope.type === 'edit') {
                    editBatch();
                    if($state.params.masterRecipients){
                        addMasterRecipients();
                    }
                } else if ($scope.type === 'import') {
                    $scope.isImporting = true;
                }

                $scope.filteredRecipients = filterRecipients();
            });
        }
    }

    function findAchCompany() {
        const modalInstance = $modal.open({
            template: require('../views/achCompaniesModalView.html'),
            size: 'md',
            controller: 'AchCompaniesModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return $scope.achCompanies;
                },
            },
        });

        modalInstance.result.then(data => {
            setAchCompanyAttributes(data);
        });
    }

    function getAchSecCodes(id) {
        achCompaniesService.getSecCodes(id).then(response => {
            $scope.secCodes = response;
        });
    }

    function validateAchCompany() {
        return (
            $scope.achCompanies.filter(item => item.companyName === $scope.batch.achCompanyName)
                .length > 0
        );
    }

    function filterRecipients() {
        if ($scope.batch !== undefined && $scope.batch.recipients !== undefined) {
            let filteredArray = $scope.batch.recipients.filter(recipient => {
                if (recipient.isDeleted) {
                    return false;
                }

                return (
                    $scope.searchFilters.text === '' ||
                    (recipient.name &&
                        recipient.name
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    (recipient.idNumber &&
                        recipient.idNumber
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1) ||
                    (recipient.accountNumber &&
                        recipient.accountNumber
                            .toLowerCase()
                            .indexOf($scope.searchFilters.text.toLowerCase()) !== -1)
                );
            });

            if ($scope.searchFilters.prenote) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.prenote && recipient.prenote
                );
            }

            if ($scope.searchFilters.hold) {
                filteredArray = filteredArray.filter(
                    recipient => $scope.searchFilters.hold && recipient.hold
                );
            }
            return filteredArray;
        }
    }

    function setReviewMode() {
        calculateAmounts();
        $scope.filteredRecipients = filterRecipients();
        if ($scope.payment && $scope.previousState.name.indexOf('createFromBatch') !== -1) {
            securityService
                .verifyUser('Edit Batch', $scope.batch, () =>
                    achBatchService.updateBatch($state.params.id, $scope.batch)
                )
                .then(response => {
                    $window.history.back();
                });
        } else {
            $scope.isEditing = false;
            $scope.isReviewing = true;
        }
    }

    function calculateAmounts() {
        const amounts = achBatchService.calculateAmounts($scope.batch.recipients);
        $scope.batch.debitAmount = amounts.debit;
        $scope.batch.creditAmount = amounts.credit;
        achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
    }

    function approveOrReject(type) {
        const isTemplate = !!$scope?.batch.isTemplate;
        const modalInstance = $modal.open({
            template: require('../views/approveRejectBatchModal.html'),
            size: 'lg',
            controller: 'ApproveRejectBatchController',
            backdrop: 'static',
            resolve: {
                type() {
                    return type;
                },
            },
        });
        modalInstance.result.then(comments => {
            if (type === 'approve') {
                const approveBatchModel = {
                    key: $scope.batchId,
                    value: comments,
                };
                securityService
                    .verifyUser('Approve Batch', approveBatchModel, () =>
                        achBatchService.approve(approveBatchModel, isTemplate)
                    )
                    .then(response => {
                        const hasError = parseIndividualErrors(response);
                        if (!hasError) {
                            $state.go(getListStateName());
                        }
                    });
            } else if (type === 'reject') {
                const rejectBatchModel = {
                    key: $scope.batchId,
                    value: comments,
                };
                securityService
                    .verifyUser('Reject Batch', rejectBatchModel, () =>
                        achBatchService.reject(rejectBatchModel)
                    )
                    .then(response => {
                        const hasError = parseIndividualErrors(response);
                        if (!hasError) {
                            $state.go(getListStateName());
                        }
                    });
            }
        });
    }

    function parseIndividualErrors(transaction) {
        let hasErrors = false;
        if (!!transaction.errorSummary && !!transaction.errorSummary.summaryMessage) {
            $scope.errorDetails = [];
            hasErrors = true;
            $scope.errorMessage = transaction.errorSummary.summaryMessage;
            angular.forEach(transaction.errorSummary.details, item => {
                if (!!item.message && !!item.messageList && item.messageList.length > 0) {
                    angular.forEach(item.messageList, message => {
                        $scope.errorDetails.push(message.value);
                    });
                } else if (item.message) {
                    $scope.errorDetails.push(item.message);
                }
            });
        } else {
            clearErrors();
        }
        return hasErrors;
    }

    function clearErrors() {
        $scope.errorMessage = null;
        $scope.errorDetails = null;
    }

    function createBatch() {
        achBatchService.create($scope.batch).then(response => {
            $scope.batch = response;
            $scope.batch.isTemplate = true;
            $scope.wizardController.goToNextStep();
            achBatchService.updateFilterCounts($scope.counts, $scope.batch.recipients);
        });
    }

    function goBack() {
        $scope.isReviewing = false;

        if ($scope.type === 'import') {
            $scope.isImporting = true;
        } else {
            $scope.isEditing = true;
        }

        $scope.wizardStep = 2;
    }

    function print() {
        $window.print();
    }

    function setAchCompanyAttributes(data) {
        $scope.batch.achCompanyName = data.companyName || '';
        $scope.batch.achCompanyId = data.companyId || '';
        $scope.batch.secCode = data.secCodes ? data.secCodes[0].code : '';
        $scope.secCodes = data.secCodes || [];
        $scope.batch.entryDescription = data.entryDescription || '';
        $scope.batch.discretionaryData = data.discretionaryData || '';

        $scope.batch.achCompany = data;
    }

    function valid() {
        return getRecipientErrors().length === 0;
    }

    function invalid() {
        return !valid();
    }

    function getRecipientErrors() {
        const errors = [];

        const invalidRecipientCount = recipientValidatorService(
            $scope.batch.recipients,
            $scope.batch.achCompany
        ).countErrors();

        if (invalidRecipientCount > 0) {
            if (invalidRecipientCount === 1) {
                errors.push(`${invalidRecipientCount} recipient has one or more invalid fields.`);
            } else {
                errors.push(`${invalidRecipientCount} recipients have one or more invalid fields.`);
            }
        }

        const { achCompanyId } = $scope.batch;
        if (!achCompanyId || achCompanyId.$invalid) {
            errors.push('ACH Company ID is a required field.');
        }

        const { entryDescription } = $scope.batch;
        if (!entryDescription || entryDescription.$invalid) {
            errors.push(
                'Entry description is a required field.  If uploading a file, the entry description in the UI must match the entry description in the file being uploaded.'
            );
        }

        for (const type in $scope.form.$error) {
            switch (type) {
                case 'onUsTransactionsRequired':
                    errors.push(
                        `At least ${$scope.batch.achCompany.onUsTransactionsRequired} recipients are required to have "On Us" routing numbers.`
                    );
                    break;

                case 'onUsAmountRequiredPerBatch':
                    errors.push(
                        `At least ${$scope.batch.achCompany.onUsAmountRequiredPerBatch}% of the batch amount must be allocated to "On Us" routing numbers.`
                    );
                    break;

                case 'batchBalanceRequirements':
                    if ($scope.batch.achCompany.batchBalanceRequirements === 'Balanced') {
                        errors.push('Debit amount total must be equal to Credit amount total.');
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Full Offset'
                    ) {
                        errors.push(
                            `Transaction type must be set to "${$scope.batch.recipients[0].transactionType}" on all recipients.`
                        );
                    } else if (
                        $scope.batch.achCompany.batchBalanceRequirements ===
                        'Unbalanced - Partial Offset'
                    ) {
                        errors.push(
                            'Debit and Credit amounts must be greater than zero and different from one another.'
                        );
                    }

                    break;

                case 'required':
                    // Don't need to do anything
                    break;

                default:
                    console.warn(`Unhandled error type: ${type}`);
            }
        }

        return errors;
    }

    function showAddendaModal(recipient) {
        const modalInstance = $modal.open({
            template: require('../views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: $scope.batch,
                        recipient,
                    };
                },
                readOnly() {
                    return true;
                },
            },
        });

        modalInstance.result.then(data => {});
    }

    function cancelImport() {
        if ($scope.form.$dirty) {
            const modalOptions = {
                closeButtonText: 'Continue Editing',
                actionButtonText: 'OK',
                headerText: 'Cancel',
                bodyText: 'Are you sure you want to cancel Batch Information?',
                submit(result) {
                    $modalInstance.close();
                    $state.go(getListStateName());
                },
            };

            var $modalInstance = modalService.showModal({}, modalOptions);
        } else {
            $state.go(getListStateName());
        }
    }
}

BatchDetailController.$inject = [
    '$scope',
    '$rootScope',
    '$modal',
    '$state',
    '$window',
    '$timeout',
    'modalService',
    'entitlementsService',
    'achBatchService',
    'achCompaniesService',
    'achSettingsService',
    'achBankService',
    'securityService',
    'activityService',
    'resourceType',
    'auditCode',
    'regexConstants',
    'recipientValidatorService',
];
