MasterRecipientsListController.$inject = [
    'toaster',
    'achMasterRecipientService',
    'achBankService',
    'regexConstants',
    '$uibModal',
    'entitlementsService',
    '$state',
    '$timeout',
    'spinnerService',
];

export default function MasterRecipientsListController(
    toaster,
    achMasterRecipientService,
    achBankService,
    regexConstants,
    $uibModal,
    entitlementsService,
    $state,
    $timeout,
    spinnerService
) {
    const vm = this;
    const catchAllRegex = /^.*$/;

    vm.cancelEditRecipient = cancelEditRecipient;
    vm.editFormName = editFormName;
    vm.filterRecipients = filterRecipients;
    vm.getRecipientNameRegex = getRecipientNameRegex;
    vm.getSelectedRecipientsCount = getSelectedRecipientsCount;
    vm.hasRecipientAction = hasRecipientAction;
    vm.hideAddRecipientForm = hideAddRecipientForm;
    vm.isPendingApproval = isPendingApproval;
    vm.isEditFormShown = isEditFormShown;
    vm.isSelectedRecipientsModalShown = isSelectedRecipientsModalShown;
    vm.pageSizeChange = pageSizeChange;
    vm.paginate = paginate;
    vm.recipientTextFilterDisabled = recipientTextFilterDisabled;
    vm.recipientTableShown = recipientTableShown;
    vm.resetSearchForm = resetSearchForm;
    vm.saveRecipient = saveRecipient;
    vm.approveRecipient = approveRecipient;
    vm.approveSelectedRecipients = approveSelectedRecipients;
    vm.rejectRecipient = rejectRecipient;
    vm.rejectSelectedRecipients = rejectSelectedRecipients;
    vm.deleteRecipient = deleteRecipient;
    vm.deleteSelectedRecipients = deleteSelectedRecipients;
    vm.createPayment = createPayment;
    vm.updateSelectionList = updateSelectionList;
    vm.saveRecipientDisabled = saveRecipientDisabled;
    vm.searchMasterRecipients = searchMasterRecipients;
    vm.showAddendaModal = showAddendaModal;
    vm.showEditAddendaModal = showEditAddendaModal;
    vm.showEditForm = showEditForm;
    vm.showPagination = showPagination;
    vm.showAddRecipientForm = showAddRecipientForm;
    vm.sidebarClasses = sidebarClasses;
    vm.sort = sort;
    vm.sortIconClasses = sortIconClasses;
    vm.toggleSelectedRecipientsModal = toggleSelectedRecipientsModal;
    vm.disableApproveReject = disableApproveReject;
    vm.disableDelete = disableDelete;
    vm.selectOrDeselectAllRecipients = selectOrDeselectAllRecipients;
    vm.showRecipientModal = showRecipientModal;
    vm.canApproveRecipient = canApproveRecipient;
    vm.canEditRecipient = canEditRecipient;
    vm.disableCreatePayment = disableCreatePayment;
    vm.getTransactionTypeAbbreviation = getTransactionTypeAbbreviation;
    vm.importMasterRecipients = importMasterRecipients;
    vm.isImportingRecipients = isImportingRecipients;
    vm.removeImportedRecipient = removeImportedRecipient;
    vm.cancelImport = cancelImport;
    vm.saveImportedRecipients = saveImportedRecipients;
    vm.cancelImportClasses = cancelImportClasses;
    vm.createPaymentText = $state.params.achTemplate ? 'Create Template from Selected' : 'Create Payment from Selected';

    vm.accountTypes = {
        1: 'Checking',
        2: 'Savings',
        3: 'Loans',
        7: 'GL',
    };
    vm.achBanks = [];
    vm.allItemsAreSelected = false;
    vm.amountFilter = {
        minimumAmount: 0,
        maximumAmount: 0,
    };
    vm.masterRecipientStatusTypes = {
        0: 'All',
        1: 'Pending Approval',
        2: 'Ready',
        3: 'Rejected',
    };
    vm.modalRecipients = null;
    vm.recipientFilter = {
        name: '',
        statusTypeId: '0',
    };
    vm.addRecipientFormShown = false;
    vm.newRecipient = {};
    vm.recipients = [];
    vm.recipientTextFilter = '';
    vm.orderColumn = 'RecipientName';
    vm.orderDescending = false;
    vm.pageSizeOptions = [10, 25, 50, 100];
    vm.pagination = {
        pageSize: vm.pageSizeOptions[0],
        pageNumber: 1,
        orderBys: buildOrderBys(),
    };
    vm.pageStart = 0;
    vm.pageEnd = 0;
    vm.selectedRecipients = {};
    vm.totalRecipientCount = 0;
    vm.transactionTypes = {
        1: {
            abbreviation: 'CR',
            name: 'Credit',
        },
        2: {
            abbreviation: 'DR',
            name: 'Debit',
        },
    };
    vm.recipientExtensionData = {};
    vm.canCreate = entitlementsService.hasEntitlement('Create Recipient');
    vm.canDelete = entitlementsService.hasEntitlement('Delete Recipient');
    vm.downloadFormats = ['CSV', 'PDF'];
    vm.downloadPageId = 'ACHMasterRecipientsList';
    vm.lastFilterPromise = null;
    vm.importedRecipients = [];
    vm.showImportError = false;
    vm.accountNumberMaxLength = 17;
    vm.recipientNameMaxLength = 22;
    vm.achCompanyId = $state.params?.achPayment?.achCompany?.id;

    const canEdit = entitlementsService.hasEntitlement('Edit Recipient');
    const canApprove = entitlementsService.hasEntitlement('Approve Recipient');
    const readyStatusTypeId = 2;
    const spinnerServiceGroupName = 'masterRecipientsList';

    Object.freeze(vm.accountTypes);
    Object.freeze(vm.masterRecipientStatusTypes);
    Object.freeze(vm.transactionTypes);

    function init() {
        vm.lastSearch = getMasterRecipientsRequest();
        getMasterRecipients(vm.lastSearch).promise.then(response => {
            if (response.pagedRecipients.items.length === 0) {
                const sidebarFunction = function () {
                    if (vm.sidebarContainerController) {
                        vm.sidebarContainerController.toggleCollapse();
                    } else {
                        $timeout(sidebarFunction);
                    }
                };
                sidebarFunction();
            }
        });
        spinnerService.configureGroup(spinnerServiceGroupName, ['GetMasterRecipients']);
    }

    function canApproveRecipient(recipient) {
        return recipient.canApproveReject && canApprove;
    }

    function canEditRecipient(recipient) {
        return recipient.canEdit && canEdit;
    }

    function disableApproveReject() {
        if (getSelectedRecipientsCount() === 0 || !canApprove) return true;

        const recipientIds = Object.getOwnPropertyNames(vm.selectedRecipients);
        return !recipientIds.every(recipientId =>
            canApproveRecipient(vm.selectedRecipients[recipientId])
        );
    }

    function disableDelete() {
        return getSelectedRecipientsCount() === 0 || !vm.canDelete;
    }

    function disableCreatePayment() {
        if ($state.params.achPayment != null && $state.params.achPayment.id) {
            if (
                !entitlementsService.hasEntitlement(
                    'ACH, Payment, Full Edit',
                    'ACH, Payment, Partial Edit'
                )
            ) {
                return true;
            }
        } else if (!entitlementsService.hasEntitlement('ACH, Batch, CreatePayment')) {
            return true;
        }
        const selectedRecipientIds = Object.getOwnPropertyNames(vm.selectedRecipients);
        return (
            selectedRecipientIds.length === 0 ||
            !selectedRecipientIds.every(
                recipientId =>
                    vm.selectedRecipients[recipientId].achMasterRecipientStatusTypeId ===
                    readyStatusTypeId
            )
        );
    }

    function selectOrDeselectAllRecipients() {
        const newValue = vm.allItemsAreSelected;
        angular.forEach(vm.recipients, recipient => {
            recipient.isSelected = newValue;
            vm.updateSelectionList(recipient);
        });
    }

    function buildOrderBys() {
        return [
            {
                name: vm.orderColumn,
                descending: vm.orderDescending,
            },
        ];
    }

    function cancelEditRecipient(recipient) {
        const recipientExtension = vm.recipientExtensionData[recipient.id];
        angular.copy(recipientExtension.originalRecipient, recipient);
        recipientExtension.showEditForm = false;
        vm[editFormName(recipient)].$setPristine();
    }

    function createRecipientExtensionData() {
        const recipientExtensionData = {};
        vm.recipients.forEach(recipient => {
            recipientExtensionData[recipient.id] = {
                approvalAttributes: {
                    approvalEntity: 'AchMasterRecipient',
                    approvalCount: recipient.approvalCount,
                    createdBy: recipient.createdBy,
                    updatedBy: recipient.updatedBy,
                    productId: recipient.id,
                },
                showEditForm: false,
            };
        });
        vm.recipientExtensionData = recipientExtensionData;
    }

    function editFormName(recipient) {
        return `editRecipient${recipient.id}`;
    }

    function filterRecipients() {
        const request = angular.copy(vm.lastSearch);
        request.searchText = getSearchText();

        if (request.searchText === vm.lastSearch.searchText) return;

        $timeout(() => {
            const searchText = getSearchText();
            if (request.searchText !== searchText) return;

            request.pagingParameters.currentPage = 1;
            vm.lastSearch = request;
            spinnerService.startGroupRequest(spinnerServiceGroupName);
            vm.lastFilterPromise = getMasterRecipients(request, vm.cancelRequestPromise);
            vm.lastFilterPromise.promise.finally(filterPromiseComplete);
        }, 400);
    }

    function getMasterRecipients(request) {
        cancelPreviousSearch();
        const getRecipients = achMasterRecipientService.getMasterRecipients(request);
        getRecipients.promise.then(response => {
            vm.recipients = response.pagedRecipients.items;
            angular.forEach(vm.recipients, recipient => {
                if (vm.selectedRecipients[recipient.id] !== undefined) {
                    recipient.isSelected = true;
                    vm.selectedRecipients[recipient.id] = recipient;
                }
            });
            vm.totalRecipientCount = response.pagedRecipients.totalCount;
            vm.pageStart =
                (response.pagedRecipients.pageNumber - 1) * response.pagedRecipients.pageSize + 1;
            vm.pageEnd =
                (response.pagedRecipients.pageNumber - 1) * response.pagedRecipients.pageSize +
                response.pagedRecipients.recordCountInCurrentPage;

            createRecipientExtensionData();
            return response;
        });
        return getRecipients;
    }

    function cancelPreviousSearch() {
        if (vm.lastFilterPromise !== null) {
            vm.lastFilterPromise.cancel();
            filterPromiseComplete();
        }
    }

    function filterPromiseComplete() {
        vm.lastFilterPromise = null;
        spinnerService.stopGroupRequest(spinnerServiceGroupName);
    }

    function getRecipientNameRegex() {
        if (vm.recipientFilter.name != null && vm.recipientFilter.name.trim().length > 0) {
            return regexConstants.AlphaNumericPlusSpecialCharsRegex;
        }

        return catchAllRegex;
    }

    function getSelectedRecipientsCount() {
        return Object.keys(vm.selectedRecipients).length;
    }

    function hasRecipientAction(recipient) {
        return canApproveRecipient(recipient) || canEditRecipient(recipient) || vm.canDelete;
    }

    function hideAddRecipientForm() {
        vm.addRecipientFormShown = false;
    }

    function isEditFormShown(recipient) {
        return vm.recipientExtensionData[recipient.id].showEditForm;
    }

    function isPendingApproval(recipient) {
        return recipient.achMasterRecipientStatusTypeId === 1;
    }

    function isSelectedRecipientsModalShown() {
        return vm.modalRecipients !== null;
    }

    function pageSizeChange() {
        vm.pagination.pageNumber = 1;
        paginate();
    }

    function paginate() {
        vm.lastSearch.pagingParameters = vm.pagination;
        getMasterRecipients(vm.lastSearch);
    }

    function recipientTableShown() {
        return vm.recipients.length || vm.addRecipientFormShown;
    }

    function recipientTextFilterDisabled() {
        return vm.recipients.length === 0 && vm.recipientTextFilter.length === 0;
    }

    function resetSearchForm() {
        vm.amountFilter = {
            amountType: 'undefined',
        };
        vm.recipientFilter.name = '';
        vm.recipientFilter.statusTypeId = '0';
        vm.masterRecipientsSearchForm.$setPristine();
    }

    function saveRecipient(recipient, rowIndex) {
        achMasterRecipientService.saveMasterRecipient(recipient).then(response => {
            toaster.save('Recipient');
            const savedRecipient = response.achMasterRecipients[0];
            if (rowIndex == null) {
                vm.recipients.push(savedRecipient);
                hideAddRecipientForm();
            } else {
                angular.copy(savedRecipient, vm.recipients[rowIndex]);
            }

            createRecipientExtensionData();
        });
    }

    function approveRecipient(recipient) {
        approveRecipients([recipient.id]);
    }

    function approveSelectedRecipients() {
        approveRecipients(getSelectedRecipientIds());
    }

    function rejectRecipient(recipient) {
        rejectRecipients([recipient.id]);
    }

    function rejectSelectedRecipients() {
        rejectRecipients(getSelectedRecipientIds());
    }

    function deleteRecipient(recipient) {
        deleteRecipients([recipient.id]);
    }

    function deleteSelectedRecipients() {
        deleteRecipients(getSelectedRecipientIds());
    }

    function createPayment() {
        const achParams = {
            masterRecipients: createRecipientsForPayment(),
            payment: $state.params.achPayment,
            template: $state.params.achTemplate,
        };
        if ($state.params.achPayment && $state.params.achPayment.id) {
            achParams.id = $state.params.achPayment.id;
            achParams.type = 'edit';
            achParams.list = 'payment-list';
            $state.go('payables.ach.payment-detail', achParams);
        } else if($state.params.achTemplate && $state.params.achTemplate.id){
            achParams.id = $state.params.achTemplate.id;
            achParams.type = 'edit';
            achParams.list = 'template-list';
            $state.go('payables.ach.batch-detail', achParams);
        } else if($state.params.achTemplate){
            $state.go('payables.ach.batch.create', achParams);
        } else {
            $state.go('payables.ach.payments.create', achParams);
        }
    }

    function createRecipientsForPayment() {
        const paymentRecipients = [];
        for (const recipientId in vm.selectedRecipients) {
            const recipient = vm.selectedRecipients[recipientId];
            paymentRecipients.push({
                name: recipient.recipientName,
                idNumber: recipient.recipientIdNumber,
                accountNumber: recipient.accountNumber,
                accountType: vm.accountTypes[recipient.accountTypeId],
                routingNumber: recipient.routingNumber,
                transactionType: getTransactionTypeAbbreviation(recipient),
                amount: recipient.defaultAmount,
                addenda: recipient.addenda ? [{ value: recipient.addenda }] : [],
                achMasterRecipientId: recipient.id,
            });
        }

        return paymentRecipients;
    }

    function updateSelectionList(recipient) {
        if (recipient.isSelected) {
            vm.selectedRecipients[recipient.id] = recipient;
            if (vm.modalRecipients !== null) {
                vm.modalRecipients[recipient.id] = recipient;
            }
        } else {
            delete vm.selectedRecipients[recipient.id];
        }

        evaluateAllItemsAreSelected();
    }

    function saveRecipientDisabled(recipientForm) {
        return recipientForm.$pristine || recipientForm.$invalid;
    }

    function searchMasterRecipients() {
        vm.selectedRecipients = {};
        vm.pagination.currentPage = 1;
        vm.lastSearch = getMasterRecipientsRequest();
        getMasterRecipients(vm.lastSearch).promise.then(() => {
            vm.sidebarContainerController.setCollapsed(true);
        });
    }

    function getMasterRecipientsRequest() {
        return {
            pagingParameters: vm.pagination,
            amountFilter: vm.amountFilter,
            recipientName: vm.recipientFilter.name,
            achMasterRecipientStatusTypeId: vm.recipientFilter.statusTypeId,
            searchText: getSearchText(),
            achCompanyId: vm.achCompanyId,
        };
    }

    function getSearchText() {
        return vm.recipientTextFilter.length < 3 ? '' : vm.recipientTextFilter;
    }

    function showAddendaModal(recipient, readOnly) {
        const addendaRecipient = angular.copy(recipient);
        addendaRecipient.addenda = [{ value: recipient.addenda }];

        return $uibModal.open({
            template: require('../../batch/views/addendaModalView.html'),
            size: 'lg',
            controller: 'AddendaModalController',
            backdrop: 'static',
            resolve: {
                data() {
                    return {
                        addendaTypes: [],
                        batch: null,
                        recipient: addendaRecipient,
                    };
                },
                readOnly() {
                    return readOnly;
                },
            },
        });
    }

    function showEditAddendaModal(recipient) {
        const modalInstance = showAddendaModal(recipient, false);

        modalInstance.result.then(result => {
            recipient.addenda = result.addendas[0].value;
            vm[editFormName(recipient)].$setDirty();
        });
    }

    function showEditForm(recipient) {
        const achBankPromise = loadAchBanks();
        const recipientExtensionData = vm.recipientExtensionData[recipient.id];
        recipientExtensionData.originalRecipient = angular.copy(recipient);
        recipientExtensionData.showEditForm = true;

        // So we are not left wondering why the save button is disabled
        $timeout(() => {
            vm[editFormName(recipient)].$setDirty();
            vm[editFormName(recipient)].routingNumber.$setDirty();

            const validateRoutingNumber = function () {
                vm[editFormName(recipient)].routingNumber.$setValidity(
                    'parse',
                    vm.achBanks.some(bank => bank.bankId === recipient.routingNumber)
                );
            };

            if (achBankPromise) {
                achBankPromise.then(validateRoutingNumber);
            } else {
                validateRoutingNumber();
            }
        });

        // because sometimes angular is trash
        recipient.transactionTypeId = recipient.transactionTypeId.toString();
        recipient.accountTypeId = recipient.accountTypeId.toString();
    }

    function showPagination() {
        return vm.totalRecipientCount > vm.recipients.length;
    }

    function showAddRecipientForm() {
        loadAchBanks();

        vm.newRecipient = {
            accountTypeId: '1',
            transactionTypeId: '1',
        };
        vm.addRecipientFormShown = true;
    }

    function showRecipientModal(recipient) {
        $uibModal.open({
            template: require('../views/masterRecipientModal.html'),
            controller: 'MasterRecipientModalController',
            bindToController: true,
            controllerAs: 'vm',
            resolve: {
                recipient() {
                    return {
                        id: recipient.id,
                        status: vm.masterRecipientStatusTypes[
                            recipient.achMasterRecipientStatusTypeId
                        ],
                        transactionType: vm.transactionTypes[recipient.transactionTypeId].name,
                        recipientName: recipient.recipientName,
                        recipientIdNumber: recipient.recipientIdNumber,
                        accountType: vm.accountTypes[recipient.accountTypeId],
                        accountNumber: recipient.accountNumber,
                        defaultAmount: recipient.defaultAmount,
                        routingNumber: recipient.routingNumber,
                        mostRecentAudit: recipient.mostRecentAudit,
                        showSuccessCheckMark:
                            recipient.achMasterRecipientStatusTypeId === readyStatusTypeId,
                    };
                },
            },
        });
    }

    function sort(newOrderColumn) {
        if (newOrderColumn === vm.orderColumn) {
            vm.orderDescending = !vm.orderDescending;
        } else {
            vm.orderDescending = false;
            vm.orderColumn = newOrderColumn;
        }
        vm.pagination.orderBys = buildOrderBys();
        vm.pagination.pageNumber = 1;
        paginate();
    }

    function sortIconClasses(sortField) {
        const classes = ['fa'];
        if (sortField !== vm.orderColumn) {
            classes.push('fa-sort');
        } else {
            classes.push(vm.orderDescending ? 'fa-sort-desc' : 'fa-sort-asc');
        }

        return classes.join(' ');
    }

    function sidebarClasses() {
        if (!vm.sidebarContainerController) return '';

        return vm.sidebarContainerController.isCollapsed() ? 'collapsed' : '';
    }

    function toggleSelectedRecipientsModal() {
        if (vm.modalRecipients === null) {
            vm.modalRecipients = {};
            for (const id in vm.selectedRecipients) {
                vm.modalRecipients[id] = vm.selectedRecipients[id];
            }
        } else {
            vm.modalRecipients = null;
        }
    }

    function getTransactionTypeAbbreviation(recipient) {
        return vm.transactionTypes[recipient.transactionTypeId].abbreviation;
    }

    function importMasterRecipients() {
        const modalInstance = $uibModal.open({
            template: require('../../batch/views/importRecipientsModal.html'),
            controller: 'ImportRecipientsModalController',
            backdrop: 'static',
        });

        modalInstance.result.then(result => {
            loadAchBanks();

            const accountTypes = {};
            for (const accountTypeId in vm.accountTypes) {
                accountTypes[vm.accountTypes[accountTypeId]] = accountTypeId;
            }

            const transactionTypes = {};
            for (const transactionTypeId in vm.transactionTypes) {
                transactionTypes[vm.transactionTypes[transactionTypeId].abbreviation] =
                    transactionTypeId;
            }

            vm.showImportError = result.length === 0;
            if (vm.showImportError) return;

            vm.importedRecipients = result.map(recipient => ({
                recipientName: recipient.name,
                recipientIdNumber: recipient.idNumber,
                accountNumber: recipient.accountNumber,
                accountTypeId: accountTypes[recipient.accountType],
                routingNumber: recipient.routingNumber,
                transactionTypeId: transactionTypes[recipient.transactionType],
                defaultAmount: recipient.amount,
            }));

            $timeout(() => {
                vm.importForm.$setDirty();
                for (let iRecipient = 0; iRecipient < vm.importedRecipients.length; ++iRecipient) {
                    vm.importForm[`routingNumber${iRecipient}`].$setValidity(
                        'parse',
                        result[iRecipient].isRoutingNumberValid
                    );
                    [
                        'recipientName',
                        'recipientIdNumber',
                        'accountNumber',
                        'accountTypeId',
                        'routingNumber',
                        'transactionTypeId',
                        'defaultAmount',
                    ].forEach(formField => {
                        vm.importForm[formField + iRecipient].$setDirty();
                    });
                }
            });
        });
    }

    function isImportingRecipients() {
        return vm.importedRecipients.length || vm.showImportError;
    }

    function removeImportedRecipient(importedRecipientIndex) {
        vm.importedRecipients.splice(importedRecipientIndex, 1);
    }

    function cancelImport() {
        vm.importedRecipients = [];
        vm.showImportError = false;
    }

    function saveImportedRecipients() {
        achMasterRecipientService.saveMasterRecipient(vm.importedRecipients).then(response => {
            vm.importedRecipients = [];
            toaster.save('Recipients');
            getMasterRecipients(vm.lastSearch);
        });
    }

    function cancelImportClasses() {
        return vm.showImportError ? 'btn-outline-primary' : 'btn-link';
    }

    // private
    function getSelectedRecipientIds() {
        return Object.getOwnPropertyNames(vm.selectedRecipients).map(achMasterRecipientId =>
            parseInt(achMasterRecipientId)
        );
    }

    // private
    function evaluateAllItemsAreSelected() {
        vm.allItemsAreSelected = vm.recipients.every(recipient => recipient.isSelected);
    }

    // private
    function loadAchBanks() {
        if (vm.achBanks.length === 0) {
            return achBankService.getAllBanks().then(response => {
                vm.achBanks = response;
            });
        }
    }

    // private
    function approveRecipients(recipientIds) {
        achMasterRecipientService.approveMasterRecipients(recipientIds).then(response => {
            toaster.save('Recipient');
            searchMasterRecipients();
        });
    }

    // private
    function rejectRecipients(recipientIds) {
        achMasterRecipientService.rejectMasterRecipients(recipientIds).then(response => {
            toaster.reject('Recipient');
            searchMasterRecipients();
        });
    }

    // private
    function deleteRecipients(recipientIds) {
        achMasterRecipientService.deleteMasterRecipients(recipientIds).then(response => {
            toaster.delete('Recipient');
            searchMasterRecipients();
        });
    }

    init();
}
