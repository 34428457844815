// cspell: ignore createtransfertemplate electronicdocuments transfertemplatedetailsview wausaustatements
/* eslint-disable global-require */
import type { DiContainer } from '@jack-henry/frontend-utils/di';
import { AuthenticationService } from '@treasury/domain/services/authentication/authentication.service';
import { Feature, FeatureFlagService } from '@treasury/domain/services/feature-flags';
import { Ng1StateDeclaration, StateParams } from '@uirouter/angularjs';
import { loadNewModules } from './moduleHelper.js';

export interface TmStateDeclaration extends Ng1StateDeclaration {
    allowAnonymous?: boolean;
    hideNavigationMenu?: boolean;
    preventNavigationAudit?: boolean;
    optionalViewClasses?: string;
    bodyLayout?: string;
    fullPageUI?: boolean;
}

export function createStates(institutionId: string): Record<string, TmStateDeclaration> {
    return {
        institution: {
            url: `/${institutionId}`,
            redirectTo: 'login',
            allowAnonymous: true,
        },

        institutionWithSlash: {
            url: `/${institutionId}/`,
            redirectTo: 'login',
            allowAnonymous: true,
        },

        version: {
            template: `<div id='versionTreasury'>${(window as any).treasury?.version}</div>`,
            url: `/version`,
            allowAnonymous: true,
        },
        dark: {
            templateProvider: async () => {
                await import('../src/dark/dark-container.js');
                return `<dark-container institution=${institutionId}></dark-container>`;
            },
            url: `/${institutionId}/dark`,
        },
        demo: {
            templateProvider: async () => {
                await import('../src/dark/dark-container.js');
                return `<dark-container institution=${institutionId}></dark-container>`;
            },
            url: `/${institutionId}/demo`,
        },
        'dev-sandbox': {
            template: `<dev-sandbox></dev-sandbox>`,
            url: `/${institutionId}/dev-sandbox`,
            allowAnonymous: true,
        },
        login: {
            url: `/${institutionId}/login`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                'TmDi',
                async ($injector: ng.auto.IInjectorService, TmDi: DiContainer) => {
                    try {
                        const authenticationService = await TmDi.getAsync(AuthenticationService);
                        const uisResp = await authenticationService.uisForcedMetadataPromise;
                        if (uisResp && uisResp.isUisEnabled && uisResp.uisLoginUrl) {
                            window.location.href = uisResp.uisLoginUrl;
                            return false;
                        }
                        await loadNewModules($injector, 'login');
                        return require('./login/views/login.html');
                    } catch (e) {
                        console.error(e);
                        return require('./login/views/loginError.html');
                    }
                },
            ],
        },
        logout: {
            url: `/${institutionId}/logout`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await import('../src/logout/container');
                    return `<logout-container institutionId=${institutionId}></logout-container>`;
                },
            ],
        },
        sso: {
            url: `/${institutionId}/login/sso`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await import('../src/login/sso-callback-container');
                    return `<sso-callback-container institutionId=${institutionId}></sso-callback-container>`;
                },
            ],
        },
        'user-enrollment': {
            url: `/${institutionId}/user-enrollment`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await import('../src/login/user-enrollment-container');
                    return `<user-enrollment-container institutionId=${institutionId}></user-enrollment-container>`;
                },
            ],
        },
        enrollment: {
            url: `/${institutionId}/enrollment/callback`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await import('../src/login/enrollment-callback-container');
                    return `<enrollment-callback-container institutionId=${institutionId}></enrollment-callback-container>`;
                },
            ],
        },
        'user-verification': {
            url: `/${institutionId}/user-verification`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/userVerification.html');
                },
            ],
        },
        'user-verification-reset': {
            url: `/${institutionId}/user-verification-reset`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/userVerificationReset.html');
                },
            ],
        },
        'change-password': {
            url: `/${institutionId}/change-password`,
            params: {
                nextStep: null,
                reason: null,
                daysUntilPasswordExpires: null,
            },
            hideNavigationMenu: true,
            preventNavigationAudit: true,
            allowAnonymous: true,

            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/changePassword.html');
                },
            ],
        },
        'create-security-questions': {
            url: `/${institutionId}/create-security-questions`,
            hideNavigationMenu: true,
            preventNavigationAudit: true,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/createSecurityQuestions.html');
                },
            ],
        },
        'answer-security-questions': {
            url: `/${institutionId}/answer-security-questions`,
            params: {
                nextStep: null,
                reason: null,
                daysUntilPasswordExpires: null,
            },
            hideNavigationMenu: true,
            preventNavigationAudit: true,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/answerSecurityQuestions.html');
                },
            ],
        },
        'forgot-password': {
            url: `/${institutionId}/forgot-password`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/forgotPassword.html');
                },
            ],
        },
        'email-sent-confirmation': {
            url: `/${institutionId}/email-sent-confirmation`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/emailSentConfirmation.html');
                },
            ],
        },
        'locked-out': {
            url: `/${institutionId}/locked-out`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/lockedOut.html');
                },
            ],
        },
        'terms-and-conditions-accept': {
            url: `/${institutionId}/terms-and-conditions-accept`,
            params: { termsAndConditions: null },
            hideNavigationMenu: true,
            optionalViewClasses: 'view-terms-and-conditions',
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/termsAndConditionsAccept.html');
                },
            ],
        },
        'dark-terms-and-conditions-accept': {
            templateProvider: async () => {
                await import('../src/terms-and-conditions/terms-and-conditions-accept');
                return `<terms-and-conditions-accept institution="${institutionId}"></terms-and-conditions-accept>`;
            },
            url: `/${institutionId}/dark-terms-and-conditions-accept`,
            hideNavigationMenu: true,
            allowAnonymous: true,
        },
        'verify-security-methods': {
            url: `/${institutionId}/verify-security-methods/:challengeMethodType`,
            params: { challengeMethodType: null },
            hideNavigationMenu: true,
            preventNavigationAudit: true,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/verifySecurityMethods.html');
                },
            ],
        },
        'collect-secure-token': {
            url: `/${institutionId}/register-secure-token`,
            allowAnonymous: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'login');
                    return require('./login/views/registerSecureTokenView.html');
                },
            ],
        },

        // user
        user: {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'user');
                    return '<ui-view />';
                },
            ],
        },
        'user.profile': {
            templateProvider: async () => {
                await import('../src/user/user-profile-container');
                return `<user-profile-container institution=${institutionId}></user-profile-container>`;
            },
            url: `/${institutionId}/user/profile`,
        },

        // Notifications

        notifications: {
            url: `/${institutionId}/notifications`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'notifications');
                    return require('./notifications/views/notificationList.html');
                },
            ],
        },

        // Dashboard
        dashboard: {
            url: `/${institutionId}/dashboard`,
            bodyLayout: 'dashboard page-container',
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'dashboard');
                    return require('./dashboard/views/dashboardContainerView.html');
                },
            ],
        },

        // Accounts
        accounts: {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'accounts');
                    return '<ui-view />';
                },
            ],
        },
        'accounts.list': {
            url: `/${institutionId}/accounts/list`,
            template: require('./accounts/list/views/accountsList.html'),
        },
        'accounts.transactions': {
            url: `/${institutionId}/accounts?accountId&type`,
            template: require('./accounts/transactions/views/accountTransactions.html'),
        },
        'accounts.statement': {
            url: `/${institutionId}/accounts/statement`,
            template: 'Accounts Statement',
        },

        // Payables

        payables: {
            abstract: true,
            template: '<ui-view />',
        },
        'payables.initiate': {
            url: `/${institutionId}/payables/initiate-payment`,
            template: 'Initiate Payments',
        },
        'payables.view': {
            url: `/${institutionId}/payables/view-payments`,
            template: 'View Payments',
        },

        'payables.transfers-edit': {
            url: `/${institutionId}/payables/transfers/{id}?fromAccountId&toAccountId&amount`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/edit/views/editInternalTransfer.html');
                },
            ],
        },
        'payables.transfers': {
            url: `/${institutionId}/payables/transfers?fromAccountId&toAccountId&amount`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/create/views/createInternalTransfer.html');
                },
            ],
        },
        'payables.confirmation': {
            url: `/${institutionId}/payables/confirmation/{id}`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/create/views/internalTransferConfirmationTemplate.html');
                },
            ],
            params: { transaction: null },
        },
        'payables.transferList': {
            url: `/${institutionId}/payables/tl?transactionId&transferDate`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/listView.html');
                },
            ],
        },
        'payables.transferDark': {
            templateProvider: async () => {
                await import(
                    '../src/transfers/transfer-activity/containers/transfer-activity-report.js'
                );
                return `<transfer-activity-report institution=${institutionId}></transfer-activity-report>`;
            },
            url: `/${institutionId}/payables/transfer-activity`,
        },
        'payables.recurringTransferList': {
            url: `/${institutionId}/payables/recurring-transfer-list?transactionId&transferDate`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/recurringListView.html');
                },
            ],
        },
        'payables.transferTemplatesList': {
            url: `/${institutionId}/payables/transfer-templates`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/transferTemplatesListView.html');
                },
            ],
            controller: 'TransferTemplateListController',
        },
        'payables.readytransferTemplatesList': {
            url: `/${institutionId}/payables/transfer-ready-templates`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/transferTemplatesListView.html');
                },
            ],
            controller: 'TransferTemplateListController',
            params: {
                Status: 'Ready',
            },
        },
        'payables.createtransfertemplate': {
            url: `/${institutionId}/payables/transfer/create-transfer-template`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/create/views/createInternalTransfer.html');
                },
            ],
            params: {
                createTemplateMode: 'true',
            },
        },
        'payables.transfertemplatedetailsview': {
            url: `/${institutionId}/payables/transfer/transfer-template-details/{id}?type`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/transferTemplateDetail.html');
                },
            ],
            controller: 'TransferTemplateDetailsController',
        },
        'payables.edit-transfer-template': {
            url: `/${institutionId}/payables/transfer/edit-transfer-template/{id}?type`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/create/views/createInternalTransfer.html');
                },
            ],
        },
        'payables.create-loan-payment': {
            url: `/${institutionId}/payables/transfer/create-loan-payment?fromAccountId&toAccountId&amount`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/create/views/createLoanTransfer.html');
                },
            ],
        },
        'payables.loan-payment-list': {
            url: `/${institutionId}/payables/transfer/loan-payment-list?transactionId&transferDate`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/list/views/loanPaymentListView.html');
                },
            ],
        },
        'payables.edit-loan-payment': {
            url: `/${institutionId}/payables/transfer/edit-loan-payment/{id}?fromAccountId&toAccountId&amount&paymentOptionType`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.internalTransfer');
                    return require('./payables/internalTransfer/edit/views/editInternalTransfer.html');
                },
            ],
        },
        // WIRES
        'payables.wire': {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.wire');
                    return '<ui-view />';
                },
            ],
        },
        'payables.wire.wire-file-activity': {
            url: `/${institutionId}/payables/wire/wire-file-activity`,
            params: {
                wireFileName: null,
            },
            templateProvider: async () => {
                await import('../src/wires/wire-file-activity-container');
                return `<wire-file-activity-container institution=${institutionId}></wire-file-activity-container>`;
            },
        },
        'payables.wire.wire-upload': {
            params: {
                wireFileUniqueId: null,
                fileName: null,
                fileSize: null,
            },
            templateProvider: async () => {
                await import('../src/wires/wire-upload/containers/wire-upload-container.js');
                return `<wire-upload-container institution=${institutionId}></wire-upload-container>`;
            },
            url: `/${institutionId}/payables/wire/wire-upload`,
        },
        'payables.wire.wire-upload-formats': {
            templateProvider: async () => {
                await import(
                    '../src/wires/wire-upload-formats/containers/wire-upload-formats-container.js'
                );
                return `<wire-upload-formats-container institution=${institutionId}></wire-upload-formats-container>`;
            },
            params: { id: null },
            url: `/${institutionId}/payables/wire/wire-upload-formats/{id}`,
        },
        'payables.wire.creditor-list': {
            url: `/${institutionId}/payables/wire/creditor-list`,
            template: require('./payables/wire/beneficiaries/views/beneficiaryListView.html'),
            params: {
                multipleWire: false,
            },
        },
        'payables.wire.create-creditor': {
            url: `/${institutionId}/payables/wire/create-creditor/?type`,
            template: require('./payables/wire/beneficiaries/views/createEditBeneficiaryView.html'),
        },
        'payables.wire.edit-creditor': {
            url: `/${institutionId}/payables/wire/edit-creditor/{id}`,
            template: require('./payables/wire/beneficiaries/views/createEditBeneficiaryView.html'),
            params: {
                beneficiary: null,
            },
        },
        'payables.wire.beneficiary-list': {
            url: `/${institutionId}/payables/wire/beneficiary-list`,
            template: require('./payables/wire/beneficiaries/views/beneficiaryListView.html'),
            params: {
                multipleWire: false,
            },
        },
        'payables.wire.create-beneficiary': {
            url: `/${institutionId}/payables/wire/create-beneficiary/?type`,
            template: require('./payables/wire/beneficiaries/views/createEditBeneficiaryView.html'),
        },
        'payables.wire.edit-beneficiary': {
            url: `/${institutionId}/payables/wire/edit-beneficiary/{id}`,
            template: require('./payables/wire/beneficiaries/views/createEditBeneficiaryView.html'),
            params: {
                beneficiary: null,
            },
        },
        'payables.wire.wire-list': {
            url: `/${institutionId}/payables/wire/wire-list`,
            params: {
                wireFileId: null,
                fileName: null,
            },
            templateProvider: async () => {
                if (await FeatureFlagService.isEnabled(Feature.WirePaymentActivityTotals)) {
                    await import(
                        '../src/wires/wire-payment-activity/wire-payment-activity.container'
                    );
                    return `<wire-payment-activity institution=${institutionId}></wire-payment-activity>`;
                }
                return require('./payables/wire/wires/views/wireListView.html');
            },
        },
        'payables.wire.wire-template-list': {
            url: `/${institutionId}/payables/wire/wire-template-list`,
            template: require('./payables/wire/wires/views/wireTemplateListView.html'),
        },
        'payables.wire.create-from-template': {
            url: `/${institutionId}/payables/wire/create-from-template`,
            template: require('./payables/wire/wires/views/wireTemplateListView.html'),
        },
        'payables.wire.create-from-template-lit': {
            url: `/${institutionId}/payables/wire/create-from-template/dark`,
            params: {
                id: null,
                subview: null,
            },
            templateProvider: async () => {
                await import('../src/wires/create-from-template-container');
                return `<create-from-template-container institution=${institutionId}></create-from-template-container>`;
            },
        },
        'payables.wire.recurring-wire-list': {
            url: `/${institutionId}/payables/wire/recurring-wire-list`,
            template: require('./payables/wire/wires/views/recurringWireListView.html'),
        },
        'payables.wire.wire-template-details-view': {
            url: `/${institutionId}/payables/wire/wire-template-details/{id}?type`,
            template: require('./payables/wire/wires/views/wireTemplateDetail.html'),
            controller: 'WireTemplateDetailsController',
        },
        'payables.wire.create-wire-template': {
            url: `/${institutionId}/payables/wire/create-wire-template`,
            template: require('./payables/wire/wires/views/createWireTemplateView.html'),
        },
        'payables.wire.create-wire': {
            url: `/${institutionId}/payables/wire/create-wire?beneficiaryId`,
            template: require('./payables/wire/wires/views/createWireView.html'),
        },
        'payables.wire.create-fx-wire': {
            url: `/${institutionId}/payables/wire/create-fx-wire?beneficiaryId`,
            template: require('./payables/wire/fx/views/fxWireWizard.html'),
        },
        'payables.wire.create-multiple-wire': {
            url: `/${institutionId}/payables/wire/create-multiple`,
            template: require('./payables/wire/wires/views/createMultipleWireContainerView.html'),
        },
        'payables.wire.wire-details': {
            url: `/${institutionId}/payables/wire/wires/{id}/?type`,
            params: {
                fileName: null,
                wireFileId: null,
            },
            template: require('./payables/wire/wires/views/wireDetail.html'),
            controller: 'WireDetailsController',
        },

        // ACH
        'payables.ach': {
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.ach');
                    return '<ui-view />';
                },
            ],
            controller: [
                '$scope',
                function achController($scope: ng.IScope & Record<string, any>) {
                    $scope.setSelectedBatches = (data: object) => {
                        $scope.payment = data;
                    };
                    $scope.setUploadedBatch = (data: object) => {
                        $scope.payment = data;
                    };
                },
            ],
        },
        'payables.ach.import-layout': {
            url: `/${institutionId}/payables/ach/import-layout`,
            template: require('./payables/ach/FileImport/views/importLayout.html'),
        },

        'payables.ach.recipients-activity-list': {
            url: `/${institutionId}/recipients-activity`,
            template: require('./payables/ach/recipients/views/recipientsActivityList.html'),
        },

        'payables.ach.recipients-list': {
            templateProvider: async () => {
                if (await FeatureFlagService.isEnabled(Feature.LitDomesticAch)) {
                    await import('../src/ach/ach-recipients/containers/ach-recipients-container');
                    return `<ach-recipients-container institution=${institutionId}></ach-recipients-container>`;
                }
                return require('./payables/ach/recipients/views/masterRecipientsList.html');
            },
            url: `/${institutionId}/recipients`,
            params: {
                achPayment: null,
                achTemplate: null,
            },
        },

        'payables.ach.notice-of-change': {
            templateProvider: async () => {
                await import('../src/ach/ach-notice-of-change/ach-notice-of-change-container.js');
                return `<ach-notice-of-change-container institution=${institutionId}></ach-notice-of-change-container>`;
            },
            url: `/${institutionId}/ach/ach-notice-of-change/v1`,
        },

        'payables.ach.notice-of-change-nacha': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-notice-of-change/ach-notice-of-change-container-v2.js'
                );
                return `<ach-notice-of-change-container-v2 institution=${institutionId}></ach-notice-of-change-container-v2>`;
            },
            url: `/${institutionId}/ach/ach-notice-of-change`,
        },

        'payables.ach.return-activity': {
            templateProvider: async () => {
                await import('../src/ach/ach-return-activity/ach-return-activity-container.js');
                return `<ach-return-activity-container institution=${institutionId}></ach-return-activity-container>`;
            },
            url: `/${institutionId}/ach/ach-return-activity/v1`,
        },

        'payables.ach.return-activity-nacha': {
            templateProvider: async () => {
                await import('../src/ach/ach-return-activity/ach-return-activity-container-v2.js');
                return `<ach-return-activity-container-v2 institution=${institutionId}></ach-return-activity-container-v2>`;
            },
            url: `/${institutionId}/ach/ach-return-activity`,
        },

        // BATCH
        'payables.ach.batch': {
            abstract: true,
            template: '<ui-view />',
        },

        'payables.ach.batch-list': {
            url: `/${institutionId}/list`,
            template: require('./payables/ach/batchList/views/batchListView.html'),
        },

        'payables.ach.fileactivity-list': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-file-activity/containers/ach-file-activity-container.js'
                );
                return `<ach-file-activity-container institution=${institutionId}></ach-file-activity-container>`;
            },
            url: `/${institutionId}/ach/file-activity`,
        },

        /**
         * ACH TEMPLATES (LIT)
         */
        'payables.ach.payments.templates': {
            templateProvider: async () => {
                await import('../src/ach/ach-templates/containers/ach-templates-container.js');
                return `<ach-templates-container institution=${institutionId}></ach-templates-container>`;
            },
            url: `/${institutionId}/ach/templates`,
        },

        /**
         * ACH TAX TEMPLATES (LIT)
         */
        'payables.ach.payments.tax-templates': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-tax-templates/containers/ach-tax-templates-container.js'
                );
                return `<ach-tax-templates-container institution=${institutionId}></ach-tax-templates-container>`;
            },
            url: `/${institutionId}/ach/tax-templates`,
        },

        'payables.ach.batch.create': {
            url: `/${institutionId}/create`,
            params: {
                masterRecipients: null,
                payment: null,
                template: null,
            },
            template: require('./payables/ach/batch/views/createBatchView.html'),
        },

        'payables.ach.batch.createTaxTemplate': {
            url: `/${institutionId}/create-tax`,
            template: require('./payables/ach/batch/views/createTaxTemplateView.html'),
        },

        'payables.ach.batch-detail': {
            url: `/${institutionId}/batch-detail/{id}/?type&?payment`,
            params: {
                masterRecipients: null,
                payment: null,
                template: null,
            },
            template: require('./payables/ach/batch/views/batchDetailView.html'),
        },
        'payables.ach.batch-tax-detail': {
            url: `/${institutionId}/batch-tax-detail/{id}/?type&?payment`,
            template: require('./payables/ach/batch/views/taxTemplateDetailView.html'),
        },
        'payables.ach.batch.createNewBatch': {
            url: `/${institutionId}/create/create-new-batch`,
            template: require('./payables/ach/batch/views/createBatchView.html'),
        },
        'payables.ach.batch.createClonedBatch': {
            url: `/${institutionId}/create/create-new-batch/{cloneId}`,
            template: require('./payables/ach/batch/views/createBatchView.html'),
        },
        'payables.ach.batch.createClonedTaxTemplate': {
            url: `/${institutionId}/create/create-new-taxTemplate/{cloneId}`,
            template: require('./payables/ach/batch/views/createTaxTemplateView.html'),
        },

        'payables.ach.batch.uploadBatch': {
            url: `/${institutionId}/create/upload-batch`,
            template: require('./payables/ach/batch/views/uploadBatchView.html'),
        },

        // PAYMENTS
        'payables.ach.payments': {
            abstract: true,
            template: '<ui-view />',
        },

        /**
         * ACH Payment List (LIT)
         */
        'payables.ach.payment-list': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-payment-activity/containers/ach-payment-activity-container.js'
                );
                return `<ach-payment-activity institution=${institutionId}></ach-payment-activity>`;
            },
            url: `/${institutionId}/ach/payment-list`,
            params: {
                fileArchiveIds: null,
            },
        },

        'payables.ach.fileactivity-list-param': {
            url: `/${institutionId}/fileactivityList/{id}`,
            template: require('./payables/ach/payments/views/achFileActivityList.html'),
            params: {
                id: null,
            },
        },

        'payables.ach.recurring-payment-list': {
            templateProvider: async () => {
                await import(
                    '../src/ach/recurring-ach-payments/containers/recurring-ach-payments.js'
                );
                return `<recurring-ach-payments institution=${institutionId}></recurring-ach-payments>`;
            },
            url: `/${institutionId}/ach/recurring-payments`,
        },

        // ACH PAYMENTS WORKFLOW
        'payables.ach.payments.create': {
            url: `/${institutionId}/ach-payments/create`,
            template: require('./payables/ach/payments/views/createNewPaymentView.html'),
            params: {
                clearSelected: true,
                masterRecipients: null,
                payment: null,
                step: null,
            },
        },
        'payables.ach.payments.create-dark': {
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            url: `/${institutionId}/ach/domestic/payment/create/`,
            params: {
                paymentRecipients: null,
                id: null,
            },
        },
        'payables.ach.payments.file-dark': {
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            url: `/${institutionId}/ach/domestic/payment/file/`,
        },
        'payables.ach.payments.initiate-dark': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            params: {
                payment: null,
                batchList: [],
            },
            url: `/${institutionId}/ach/domestic/payment/initiate/{id}`,
        },
        'payables.ach.payments.initiate-batch-dark': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            params: {
                payment: null,
                batchList: [],
            },
            url: `/${institutionId}/ach/domestic/payment/batch-initiate`,
        },
        'payables.ach.payments.edit-dark': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            url: `/${institutionId}/ach/domestic/payment/edit/{id}`,
        },
        'payables.ach.payments.view-dark': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import('../src/ach/ach-domestic/containers/ach-domestic-container');
                return `<ach-domestic-container institution=${institutionId}></ach-domestic-container>`;
            },
            url: `/${institutionId}/ach/domestic/payment/view/{id}`,
        },
        'payables.ach.payments.createTaxPayment': {
            url: `/${institutionId}/ach-payments/create-tax`,
            template: require('./payables/ach/payments/views/createTaxPaymentView.html'),
            params: {
                clearSelected: true,
                masterRecipients: null,
                payment: null,
                step: null,
                paymentToCopy: null,
            },
        },

        /** International ACH Payments */

        'payables.ach.payments.create-international-ach-payment': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-workflow-container.js'
                );
                return `<international-ach-workflow-container institution=${institutionId}></international-ach-workflow-container>`;
            },
            url: `/${institutionId}/ach/international-ach/payment/create/`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.initiate-international-ach-payment': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-workflow-container.js'
                );
                return `<international-ach-workflow-container institution=${institutionId}></international-ach-workflow-container>`;
            },
            url: `/${institutionId}/ach/international-ach/payment/initiate/{id}`,
            params: {
                paymentState: null,
                initiateFromTemplate: true,
            },
        },
        'payables.ach.payments.edit-international-ach-payment': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-workflow-container.js'
                );
                return `<international-ach-workflow-container institution=${institutionId} edit=${true} ></international-ach-workflow-container>`;
            },
            url: `/${institutionId}/ach/international-ach/payment/edit/{id}`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.create-international-ach-template': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-workflow-container.js'
                );
                return `<international-ach-workflow-container institution=${institutionId} template=${true}></international-ach-workflow-container>`;
            },
            url: `/${institutionId}/ach/international-ach/template/create/`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.edit-international-ach-template': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-workflow-container.js'
                );
                return `<international-ach-workflow-container institution=${institutionId} edit=${true} ></international-ach-workflow-container>`;
            },
            url: `/${institutionId}/ach/international-ach/template/edit/{id}`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.international-ach-templates': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-templates-container.js'
                );
                return `<international-ach-templates-container institution=${institutionId}></international-ach-templates-container>`;
            },
            url: `/${institutionId}/ach/international-ach/templates`,
        },

        'payables.ach.international-ach-payment-details': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-detail-container.js'
                );
                return `<international-ach-detail-container institution=${institutionId} ></international-ach-detail-container>`;
            },
            url: `/${institutionId}/ach/international-ach/payment/{id}`,
        },
        'payables.ach.international-ach-template-details': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-international/containers/international-ach-detail-container.js'
                );
                return `<international-ach-detail-container institution=${institutionId} .template=${true} ></international-ach-detail-container>`;
            },
            url: `/${institutionId}/ach/international-ach/template/{id}`,
        },

        /**
         * ACH CHILD SUPPORT
         */
        'payables.ach.payments.child-support-workflow': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-workflow-container.js'
                );
                return `<child-support-workflow-container institution=${institutionId}></child-support-workflow-container>`;
            },
            url: `/${institutionId}/ach/child-support/payment/create/`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.child-support-workflow-payment-edit': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-workflow-container.js'
                );
                return `<child-support-workflow-container institution=${institutionId}></child-support-workflow-container>`;
            },
            url: `/${institutionId}/ach/child-support/payment/edit/{id}`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.child-support-workflow-template-create': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-workflow-container.js'
                );
                return `<child-support-workflow-container institution=${institutionId}></child-support-workflow-container>`;
            },
            url: `/${institutionId}/ach/template/child-support/template/create/`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.child-support-workflow-template-edit': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-workflow-container.js'
                );
                return `<child-support-workflow-container institution=${institutionId}></child-support-workflow-container>`;
            },
            url: `/${institutionId}/ach/child-support/template/edit/{id}`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.payments.child-support-workflow-payment-initiate': {
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-workflow-container.js'
                );
                return `<child-support-workflow-container institution=${institutionId}></child-support-workflow-container>`;
            },
            url: `/${institutionId}/ach/child-support/payment/initiate/{id}`,
            params: {
                paymentState: null,
            },
        },
        'payables.ach.child-support-details': {
            url: `/${institutionId}/ach-payment-activity/child-support/{type}/{id}`,
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-details-container.js'
                );
                return `<child-support-details-container institution=${institutionId}></child-support-details-container>`;
            },
        },
        'payables.ach.payments.child-support-templates': {
            templateProvider: async () => {
                await import(
                    '../src/ach/ach-child-support/containers/child-support-templates-container.js'
                );
                return `<child-support-templates-container institution=${institutionId}></child-support-templates-container>`;
            },
            url: `/${institutionId}/ach/child-support-templates`,
        },
        /**
         *
         */
        'payables.ach.payments.createFromBatch': {
            url: `/${institutionId}/ach-payments/create/create-from-batch/?payment&upload`,
            template: require('./payables/ach/payments/views/createPaymentFromBatchView.html'),
            params: { paymentList: null },
        },
        'payables.ach.payments.createFromTaxTemplate': {
            url: `/${institutionId}/ach-payments/create/create-from-taxTemplate/?payment&upload`,
            template: require('./payables/ach/payments/views/createPaymentFromTaxTemplateView.html'),
            params: { paymentList: null },
        },
        'payables.ach.payments.createFromNachaUpload': {
            url: `/${institutionId}/ach-payments/create/create-new-payment`,
            template: require('./payables/ach/payments/views/createPaymentMultipleBatchUpload.html'),
        },
        'payables.ach.payments.reverse': {
            url: `/${institutionId}/ach-payments/reverse/{reversalId}/?type&list`,
            template: require('./payables/ach/payments/views/createReversalPaymentView.html'),
        },
        'payables.ach.payments.createNewPayment': {
            url: `/${institutionId}/ach-payments/create/create-new-payment`,
            template: require('./payables/ach/payments/views/createNewPaymentView.html'),
            params: { clearSelected: true },
        },
        'payables.ach.payment-detail': {
            url: `/${institutionId}/ach-payments/payment-detail/{id}/?type&list`,
            template: require('./payables/ach/payments/views/paymentDetail.html'),
            params: {
                masterRecipients: null,
                payment: null,
            },
        },
        'payables.ach.taxPayment-detail': {
            url: `/${institutionId}/tax-payment-detail/{id}`,
            template: require('./payables/ach/taxes/views/taxPaymentDetail.html'),
        },
        'payables.ach.payments.uploadPayment': {
            url: `/${institutionId}/ach-payments/create/upload-payment`,
            template: require('./payables/ach/payments/views/uploadPaymentView.html'),
        },
        // Payables - Stop Payment
        'payables.stp': {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.stp');
                    return '<ui-view />';
                },
            ],
        },
        'payables.stp.create': {
            url: `/${institutionId}/payables/stp/create`,
            template: require('./payables/stp/views/createStpView.html'),
        },
        'payables.stp.list': {
            url: `/${institutionId}/payables/stp/list?accountNumber`,
            template: require('./payables/stp/views/stpListView.html'),
        },
        // Payables - ARP
        'payables.arp': {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'payables.arp');
                    return '<ui-view />';
                },
            ],
        },
        'payables.arp.ach-filters': {
            templateProvider: async () => {
                await import('../src/positive-pay/ach-filters/ach-filter-container.js');
                return `<ach-filter-container institution=${institutionId} exceptionJson='{{ exceptionJson }}'></ach-filter-container>`;
            },
            url: `/${institutionId}/ach-filters`,
            params: { exceptionJson: null },
            controller: [
                '$scope',
                '$stateParams',
                function achFiltersController(
                    $scope: ng.IScope & Record<string, any>,
                    $stateParams: StateParams
                ) {
                    $scope.exceptionJson = $stateParams.exceptionJson;
                },
            ],
        },
        'payables.arp.ach-filter-workflow': {
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/ach-filters/workflow/ach-filter-workflow-container.js'
                );
                return `<ach-filter-workflow-container institution=${institutionId}></ach-filter-workflow-container>`;
            },
            url: `/${institutionId}/ach-filters/create`,
        },
        'payables.arp.ach-filter-workflow-edit': {
            params: { existingFilter: null },
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/ach-filters/workflow/ach-filter-workflow-container.js'
                );
                return `<ach-filter-workflow-container institution=${institutionId}></ach-filter-workflow-container>`;
            },
            url: `/${institutionId}/ach-filters/edit/{id}`,
        },
        'payables.arp.ach-filter-workflow-create-from-exception': {
            params: { exceptionJson: null, referringPage: null },
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/ach-filters/workflow/ach-filter-workflow-container.js'
                );
                return `<ach-filter-workflow-container institution=${institutionId}></ach-filter-workflow-container>`;
            },
            url: `/${institutionId}/ach-filters/create-from-exception/{id}`,
        },
        'payables.arp.ach-filter-workflow-create-from-decision': {
            params: { exceptionJson: null, referringPage: null },
            // eslint-disable-next-line sonarjs/no-identical-functions
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/ach-filters/workflow/ach-filter-workflow-container.js'
                );
                return `<ach-filter-workflow-container institution=${institutionId}></ach-filter-workflow-container>`;
            },
            url: `/${institutionId}/ach-filters/create-from-decision/{id}`,
        },
        'payables.arp.ach-exceptions-filter-list': {
            url: `/${institutionId}/payables/arp/ach-exception-filter`,
            template: require('./payables/arp/views/achExceptionFilterListView.html'),
        },
        'payables.arp.check-exceptions': {
            templateProvider: async () => {
                if (await FeatureFlagService.isEnabled('Feature.PositivePay.CheckException')) {
                    if (await FeatureFlagService.isEnabled(Feature.CheckExceptionV2)) {
                        await import('../src/positive-pay/check-exceptions/check-exceptions-v2.js');
                        return `<check-exceptions-v2 institution=${institutionId}></check-exceptions-v2>`;
                    }
                    await import('../src/positive-pay/check-exceptions/check-exceptions.js');
                    return `<check-exceptions institution=${institutionId}></check-exceptions>`;
                }
                return require('./payables/arp/views/arpExceptionsListView.html');
            },
            url: `/${institutionId}/payables/arp/check-exceptions`,
        },
        'payables.arp.check-exception-decision-activity': {
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/check-exceptions/check-exception-decision-activity.js'
                );
                return `<check-exception-decision-activity institution=${institutionId}></check-exception-decision-activity>`;
            },
            url: `/${institutionId}/payables/arp/check-exceptions/decision-activity`,
        },
        'payables.arp.arp-output-file-list': {
            url: `/${institutionId}/payables/arp/arp-output-file-list`,
            template: require('./payables/arp/views/arpOutputFileListView.html'),
        },
        'payables.arp.ach-exceptions-list': {
            templateProvider: async () => {
                await import('../src/positive-pay/ach-exceptions/containers/ach-exceptions-list');
                return `<ach-exceptions-list institution=${institutionId}></ach-exceptions-list>`;
            },
            url: `/${institutionId}/payables/positive-pay/ach-exceptions-list`,
        },
        'payables.arp.ach-exceptions-activity-list': {
            url: `/${institutionId}/payables/arp/ach-exceptions-activity-list?accountNumber`,
            template: require('./payables/arp/views/achExceptionsDecisionsActivityView.html'),
        },
        'payables.arp.arp-upload-format-list': {
            url: `/${institutionId}/payables/arp/arp-upload-format-list`,
            template: require('./payables/arp/views/arpUploadFormatListView.html'),
        },
        'payables.arp.arp-upload-format-edit': {
            url: `/${institutionId}/payables/arp/arp-upload-pos-pay-file-mapping/{id}`,
            template: require('./payables/arp/views/posPayFileMappingView.html'),
            params: {
                id: null,
            },
        },
        'payables.arp.create': {
            url: `/${institutionId}/payables/arp/create`,
            templateProvider: async () => {
                const showLitView = await FeatureFlagService.isEnabled(Feature.IssuedItemsVoids);

                if (showLitView) {
                    await import(
                        '../src/arp/issued-items/creation/issued-items-creation-container'
                    );
                    return `<issued-items-creation-container institution="${institutionId}"></issued-items-creation-container>`;
                }

                return require('./payables/arp/views/createArpView.html');
            },
        },
        'payables.arp.activity': {
            url: `/${institutionId}/payables/arp/issued-items-activity`,
            templateProvider: async () => {
                const showLitView = await FeatureFlagService.isEnabled(Feature.IssuedItemsOdi);

                if (showLitView) {
                    await import(
                        '../src/arp/issued-items/activity/issued-items-activity-container'
                    );
                    return `<issued-items-activity-container institution="${institutionId}"></issued-items-activity-container>`;
                }

                return require('./payables/arp/views/issuedItemsActivityView.html');
            },
        },
        'payables.arp.issued-items-file-activity-dark': {
            templateProvider: async () => {
                await import(
                    '../src/positive-pay/issued-items-file-activity/containers/issued-items-file-activity-list'
                );
                return `<issued-items-file-activity-list institution=${institutionId}></issued-items-file-activity-list>`;
            },
            url: `/${institutionId}/payables/arp/issued-items-file-activity-dark`,
        },
        'payables.arp.centrix-sso': {
            url: `/${institutionId}/payables/arp/arp-centrix-sso`,
            template: require('./payables/arp/views/centrixSsoView.html'),
        },
        'payables.billpay': {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'billPay');
                    return '<ui-view />';
                },
            ],
        },
        'payables.billpay.businessBillPay': {
            url: `/${institutionId}/payables/billpay`,
            template: require('./billPay/views/billPayView.html'),
        },

        // Receivables
        receivables: {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'receivables');
                    return '<ui-view />';
                },
            ],
        },
        'receivables.rdc-sso': {
            url: `/${institutionId}/receivables/rdc-sso`,
            template: require('./receivables/views/remoteDepositCaptureSsoView.html'),
            controller: 'RemoteDepositCaptureSsoController',
            controllerAs: 'vm',
        },
        'rdc-redirect': {
            url: `/${institutionId}/receivables/redirect/{reason}`,
            template: require('./receivables/views/remoteDepositCaptureRedirectView.html'),
            controller: 'RemoteDepositCaptureRedirectController',
            controllerAs: 'vm',
            allowAnonymous: true,
        },

        // Information Reporting

        // REPORTING CONTAINERS (LIT)
        'reports-dashboard': {
            url: `/${institutionId}/reports`,
            params: { tab: null },
            templateProvider: async () => {
                await import(
                    '../src/reporting/reporting-dashboard/containers/reporting-dashboard-container.js'
                );
                return `<reporting-dashboard-container institution=${institutionId}></reporting-dashboard-container>`;
            },
        },
        'reports-balance': {
            templateProvider: async () => {
                await import(
                    '../src/reporting/balance-reports/containers/balance-reports-container.js'
                );
                return `<balance-reports-container .id={id} institution=${institutionId}></balance-reports-container>`;
            },
            url: `/${institutionId}/reports/balance/{id}`,
        },
        'reports-transaction': {
            templateProvider: async () => {
                await import(
                    '../src/reporting/transaction-reports/containers/transaction-reports-container.js'
                );
                return `<transaction-reports-container institution=${institutionId} .id={id}></transaction-reports-container>`;
            },
            url: `/${institutionId}/reports/transactions/{id}`,
        },
        'reports-edi': {
            templateProvider: async () => {
                await import('../src/reporting/edi/containers/edi-reports-container.js');
                return `<edi-reports-container institution=${institutionId} .id={id}></edi-reports-container>`;
            },
            url: `/${institutionId}/reports/edi/{id}`,
        },
        ir: {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'informationReporting');
                    return '<ui-view />';
                },
            ],
        },
        'ir.dashboard': {
            url: `/${institutionId}/ir/dashboard`,
            template: require('./informationReporting/dashboard/views/dashboardView.html'),
            params: {
                tab: 'all',
            },
        },
        'ir.dashboard.custom': {
            url: `/${institutionId}/ir/dashboard`,
            template: require('./informationReporting/dashboard/views/dashboardView.html'),
            params: {
                tab: 'custom',
            },
        },
        'ir.dashboard.favorite': {
            url: `/${institutionId}/ir/dashboard`,
            template: require('./informationReporting/dashboard/views/dashboardView.html'),
            params: {
                tab: 'favorite',
            },
        },
        'ir.dashboard.standard': {
            url: `/${institutionId}/ir/dashboard`,
            template: require('./informationReporting/dashboard/views/dashboardView.html'),
            params: {
                tab: 'standard',
            },
        },
        'ir.electronicDocuments': {
            url: `/${institutionId}/ir/electronicdocuments`,
            template: require('./informationReporting/electronicdocuments/views/electronicdocumentView.html'),
        },
        'ir.drt': {
            url: `/${institutionId}/ir/drt/{id}`,
            template: require('./informationReporting/dateRangeTransaction/views/dateRangeTransactionView.html'),
        },
        'ir.drb': {
            url: `/${institutionId}/ir/drb/{id}`,
            template: require('./informationReporting/dateRangeBalance/views/dateRangeBalanceView.html'),
        },
        'ir.dt': {
            url: `/${institutionId}/ir/dt/{id}`,
            template: require('./informationReporting/dayTransaction/views/dayTransactionView.html'),
        },
        'ir.db': {
            url: `/${institutionId}/ir/db/{id}`,
            template: require('./informationReporting/dayBalance/views/dayBalanceView.html'),
        },
        'ir.rt': {
            url: `/${institutionId}/ir/rt`,
            template: require('./informationReporting/researchTransaction/views/researchTransactionView.html'),
        },
        'ir.edi': {
            url: `/${institutionId}/ir/edi/{id}`,
            template: require('./informationReporting/edi/views/ediView.html'),
        },
        'ir.wausauStatements': {
            url: `/${institutionId}/ir/wausaustatements`,
            template: require('./informationReporting/wausauStatements/views/wausauStatementsView.html'),
        },
        /* cspell:disable-next-line */
        'ir.bdieStatements': {
            /* cspell:disable-next-line */
            url: `/${institutionId}/ir/bdieStatements`,
            template: require('./informationReporting/bdiEStatements/views/bdiEStatementsView.html'),
        },

        // Admin

        admin: {
            abstract: true,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'admin');
                    return '<ui-view />';
                },
            ],
        },
        'admin.user-list': {
            url: `/${institutionId}/admin/users/user-list`,
            template: require('./admin/users/views/userListView.html'),
        },
        'admin.user-information': {
            url: `/${institutionId}/admin/users/{id}/user-information?view`,
            template: require('./admin/users/views/userInformationView.html'),
            params: {
                id: null,
                subview: null,
                copyUser: null,
            },
        },
        'admin.user-information-lit': {
            url: `/${institutionId}/admin/users/{id}/user-information/dark?view`,
            params: {
                id: null,
                subview: null,
                copyUser: null,
            },
            templateProvider: async () => {
                await import('../src/user/create-user-container');
                return `<create-user-container institution=${institutionId}></create-user-container>`;
            },
        },
        'admin.account-nick-names': {
            url: `/${institutionId}/admin/account-nick-names`,
            template: require('./admin/accountNickNames/views/accountNickNames.html'),
        },
        'admin.notification-configuration': {
            url: `/${institutionId}/admin/notification-configuration`,
            template: require('./admin/ConfigureNotifications/configureNotifications.html'),
            preventNavigationAudit: true,
        },
        'message-center': {
            url: `/${institutionId}/message-center`,
            templateProvider: [
                '$injector',
                async ($injector: ng.auto.IInjectorService) => {
                    await loadNewModules($injector, 'messageCenter');
                    return require('./messageCenter/views/messageCenterView.html');
                },
            ],
        },

        // Account Reconciliation Reporting
        'ir.accountReconciliation-library': {
            templateProvider: async () => {
                await import('../src/ach/ach-account-reconciliation/index.js');
                return `<ach-account-reconciliation-library-container institution="${institutionId}"></ach-account-reconciliation-library-container>`;
            },
            url: `/${institutionId}/account-reconciliation-library`,
            fullPageUI: true,
        },
        'ir.accountReconciliation-workflow': {
            templateProvider: async () => {
                await import('../src/ach/ach-account-reconciliation/index.js');
                return `<ach-account-reconciliation-report-workflow institution="${institutionId}"></ach-account-reconciliation-report-workflow>`;
            },
            params: {
                reportValues: null,
            },
            url: `/${institutionId}/account-reconciliation/{id}`,
        },
        'ir.accountReconciliation': {
            templateProvider: async () => {
                await import('../src/ach/ach-account-reconciliation/index.js');
                return `<ach-account-reconciliation-reports-container institution="${institutionId}"></ach-account-reconciliation-reports-container>`;
            },
            url: `/${institutionId}/account-reconciliation-reports`,
        },
        'not-found': {
            allowAnonymous: true,
            url: `/${institutionId}/not-found`,
            template: require('/not-found.html'),
        },
    };
}
